<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<div class="performance__content">
				<h2 class="performance__title">
					Total
					Value of
					Securities
					Processed
				</h2>
			</div>
			<div class="performance__graphic">
				<number-block class="" :intro="`Total \nValue of Securities Processed`" :numbers="totalValueSecurities" />
			</div>
		</div>
	</div>
</template>

<script>
import NumberBlock from '@/components/NumberBlock'

export default {
	name: 'SecuritiesProcessed',
	components: {
		NumberBlock,
	},
	data: () => ({
		totalValueSecurities: [
			{
				title: '2020',
				value: 2.33,
				unit: 'Quadrillion',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				title: '2019',
				value: 2.15,
				unit: 'Quadrillion',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
			{
				title: '2018',
				value: 1.85,
				unit: 'Quadrillion',
				options: {
					decimalPlaces: 2,
					prefix: '$',
				},
			},
		],
	})
}
</script>

<style scoped lang="scss">

</style>
